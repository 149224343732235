.modal-button {
    background-color: black;
    color: white;
}

.modal-button:hover {
    background-color: white !important;
    border: 1px solid black !important;
    color: black !important;
}

@media (max-width: 1231px) {

    .modal-button {
        font-size: x-small;
        width: 6.5rem;
    }
}