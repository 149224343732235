/* General styling for the section */
#about {
    padding: 20px; /* Add padding around the section */
    background-color: #f9f9f9; /* Optional: Background color for the section */
}

/* Styling for the heading */
.about {
    text-align: center;
    font-family: Roboto, sans-serif;
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 3rem;
}

/* Container for text and image */
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

/* Text content styling */
.text-content {
    max-width: 800px;
    margin-bottom: 20px;
}

.text-content h2 {
    font-size: 2rem;
    margin-bottom: 10px;
     
}

.text-content h3 {
    font-size: 0rem;
    line-height: 1.5;
}

/* Image content styling */
.image-content {
    width: 100%;
    display: flex;
    justify-content: center;
}

.image-content img {
    max-width: 100%;
    height: auto;
}

/* Services section styling */
.container1 {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
    text-align: left;
    background-color: black;
    color: #f9f9f9;
    border-radius: 2px;
    padding: 1em;
    margin-top: 1px;
}

.ourservices {
    font-size: 2rem;
    margin-bottom: 1rem;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.ourorigin {
    font-size: 2rem;
    margin-bottom: 1rem;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: bold;
    padding: 1em;
    margin-top: 1px;
}
/* App.css */


.circle-icon {
    vertical-align: middle; /* Align the icon with the text vertically */
    margin-right: 0.5em; /* Space between the icon and the text */
}

p {
    margin: 0.5em 0; /* Adjust spacing between paragraphs */
}


.service-list {
    width: 100%;
}
.span{
    color: rgb(253, 126, 20);
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .circle-icon {
        font-size: 1.5rem;
        margin-right: 0.3rem;
    }

    .container1 {
        padding: 0.5em;
    }

    .ourservices {
        font-size: 1.5rem;
    }
}

@media (max-width: 480px) {
    .circle-icon {
        font-size: 1.2rem;
        margin-right: 0.2rem;
    }

    .container1 {
        padding: 0.3em;
    }

    .ourservices {
        font-size: 1.2rem;
    }
}

@media (min-width: 768px) {
    .container {
        flex-direction: row;
        justify-content: space-between;
    }

    .text-content, .image-content {
        flex: 1;
        margin: 0 10px;
    }

    .text-content h2 {
        font-size: 2.5rem;
    }

    .text-content h3 {
        font-size: 1.25rem;
    }
}

@media (min-width: 1024px) {
    .text-content h2 {
        font-size: 3rem;
    }

    .text-content h3 {
        font-size: 1.5rem;
    }
}
