.scrolling-text {
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  width: 100%;
  height: 90px; /* Adjust height for different screen sizes */
  background: none; 
  padding: 10px 0; /* Padding for visual appeal */
  margin-top: 2rem;
  margin-bottom: 2rem;
  /* Subtle transparent shadow */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 5px; /* Optional: rounded corners */
}

.scroll-animation {
  white-space: nowrap;
  /* Ensures the logos stay in a single line */
  animation: scroll 20s linear infinite;
  /* Animation to handle continuous scroll */
}

@keyframes scroll {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.logo {
  height: 65px;
  /* Default height of logos */
  width: auto;
  /* Keep aspect ratio intact */
  margin-right: 50px;
  /* Default spacing between logos */
  vertical-align: middle;
  /* Align logos properly */
}

.trusted {
  text-align: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;

}

.trustedtext {
  font-weight: bold;
}

.span {
  color: rgb(253, 126, 20);
}

/* Media Queries for responsiveness */
@media (max-width: 1200px) {
  .logo {
    height: 40px;
    /* Smaller height for medium screens */
    margin-right: 20px;
    /* Smaller spacing for medium screens */
  }
}

@media (max-width: 768px) {
  .scrolling-text {
    height: 60px;
    /* Adjust height for small screens */
  }

  .logo {
    height: 30px;
    /* Smaller height for small screens */
    margin-right: 15px;
    /* Smaller spacing for small screens */
  }
}

@media (max-width: 480px) {
  .scrolling-text {
    height: 50px;
    /* Further adjustment for very small screens */
  }

  .logo {
    height: 25px;
    /* Even smaller height for very small screens */
    margin-right: 10px;
    /* Smaller spacing for very small screens */
  }
}