.ourleadership {
    text-align: center;
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.avatar-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 5rem; /* Space between avatars */
    justify-content: center;
    padding-top: 2rem;
}

.avatar-item {
    text-align: center;
}

.avatar {
    height: 10rem;
    width: 10rem;
    border: 3px solid #f0f0f0; /* Light border around avatars */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow */
    border-radius: 50%;
}

.avatar-name {
    margin-top: 0.5rem;
    font-size: 1rem;
    font-weight: 500;
}

@media (max-width: 768px) {
    .avatar {
        height: 8rem;
        width: 8rem;
    }
}
