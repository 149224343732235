.review-images {
    margin-top: 3rem;
    display: flex;
    justify-content: center; /* Center the content */
    flex-wrap: wrap; /* Ensure items wrap to new lines on smaller screens */
}

.review-image-col {
    display: flex;
    justify-content: center; /* Center each image within its column */
    margin-bottom: 1rem; /* Space below each column */
}

.review-image-size {
    width: 12rem;
    height: auto;
}

@media screen and (max-width: 768px) {
    .review-images {
        margin-top: 2rem;
    }
}
@media screen and (max-width: 425px) {
    .review-image-size {
        width: 8rem; /* Decrease the width for very small screens */
    }
}
