/* Logo Width */
.logo-width {
    width: 10rem;
    height: 10rem;
    margin-right: 3rem;
}

/* Navbar Styles */
.navbar {
    position: sticky;
    top: 0;
    z-index: 1000;
    /* Ensure it's on top of other elements */
    border-bottom: none !important;
    box-shadow: none !important;
    background-color: black;
    height: 80px;
    /* Set a fixed height for the navbar */
}

.container-size {
    width: 97% !important;
}

.navbar-scrolled {
    background-color: black !important;
}

/* Navbar Custom Styles */
.custom-navbar {
    display: flex;
    justify-content: space-between;
    background-color: transparent;
    margin: 0%;
}

.custom-navbar:hover {
    background-position: right center;
}

.custom-navbar .navbar-brand .brand-name {
    color: #FFFFFF;
    font-weight: bold;
}

.nav-link {
    color: white !important;
}

.nav-links .nav-link {
    font-size: small;
}


.nav-links .nav-link,
.nav-links .nav-dropdown {
    color: white;
    margin-right: 1.5rem;
    font-weight: bold;
    text-transform: uppercase;
    transition: color 0.3s ease;
}

.nav-links .nav-link:hover,
.nav-links .nav-dropdown:hover {
    color: #FFA500;
}

.nav-links .nav-dropdown .dropdown-item {
    color: white;
    font-weight: normal;
    text-align: left !important;

}

.dropdown-item {
    font-size: small !important;
    text-align: left !important;
    font-weight: 700;
}

.nav-links .nav-dropdown .dropdown-item:hover {

    color: #FFA500;
}

/* Center the nav links */
.navbar-collapse {
    display: flex;
    justify-content: center;
    flex: 1;
}



/* Search Bar Styles */
.search-form {
    position: relative;
    width: auto;
    transition: width 0.4s ease-in-out;
    margin-right: 1rem;
    display: flex;
    align-items: center;
}

.search-form:hover,
.search-form:focus-within {
    width: 300px;
}

.search-form .form-control {
    border-radius: 20px;
    background-color: white;
    border: 1px solid #ddd;
}

/* Search Button Styles */
.search-button {
    background-color: rgb(253, 126, 20);
    ;
    color: #fff;
    transition: background-color 0.3s ease;
}

.search-button:hover {
    background-color: #FFA500;
    border-color: #FFA500;
    color: #000;
}

/* Dropdown Styles */
.dropdown-multi-column .dropdown-menu {
    width: 850px;
    padding: 20px;
}

.dropdown-multi-column .dropdown-menu h6 {
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 15px;
}

.dropdown-multi-column .dropdown-menu .dropdown-item {
    white-space: normal;
    padding: 10px 15px;
}

.dropdown-multi-column .dropdown-menu .row {
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.dropdown-multi-column .dropdown-menu .col {
    padding: 0;
}

.market-logo {
    width: 3rem;
    height: 3rem;
}

.market-logo-web {
    margin-top: 1rem;
    width: 2rem;
    height: 2rem;
}

.logo-text-differ {
    display: flex;
    align-items: center;
}

.unorder-list {
    text-align: left;
    font-size: x-small;
}

.image-h6 {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    /* Adds space between the icon and text */
}

.image-h6 svg {
    font-size: 1.5rem;
    /* Adjust icon size */
}

.dropdown-links {
    text-decoration:dotted !important;
    font-weight: 700;
    margin: 0;
    line-height: 1;
    /* Ensure no extra space in the text */
    color: #000;
}

.service-tab {
    width: 850px;
    height: auto;
    padding-top: 1rem;
}

.navbar-toggler-icon {
    background-image: url("../../../public/images/menuicon.png") !important;
}

/* Search Icon Styling */
.search-container {
    position: relative;
    display: flex;
    align-items: center;
    margin-left: 1rem;
    /* Adjust as needed */
}

.search-icon {
    font-size: 1.5rem;
    cursor: pointer;
    color: white;
    /* Adjust color as needed */
}

.search-bar {
    margin-top: 1rem;
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    padding: 5px;
    width: 200px;
    transition: all 0.3s ease;
}

.search-bar.show {
    display: block;
}

.search-bar input {
    width: 100%;
    border: none;
    border-radius: 4px;
    outline: none;
}

@media (max-width: 991px) {
    .navbar {
        width: 100% !important;
    }

    .nav-link {
        text-align: left !important;
        color: black !important;
    }

}

@media (max-width: 1156px) {
    .dropdown-multi-column .dropdown-menu {
        width: 42rem;
        padding: 20px;
    }
}

@media (max-width: 1217px) {

    .nav-links .nav-link {
        margin-left: 1rem;
        font-size: xx-small;
    }
}

/* Responsive Styling for Screens Smaller Than 992px */
@media (max-width: 992px) {
    .search-form {
        display: none;
    }

    .dropdown-multi-column .dropdown-menu .row {
        display: flex;
        flex-direction: row;
        /* Keep row direction */
        flex-wrap: nowrap;
        /* Prevent columns from wrapping */
        justify-content: space-between;
    }

    .dropdown-multi-column .dropdown-menu .col {
        flex: 0 0 45%;
        /* Adjust width for mobile, allowing some gap */
    }

    .navbar {
        margin: 0%;
    }

    .custom-navbar {
        flex-direction: column;
        height: 10rem !important;
    }

    .navbar-collapse {
        justify-content: center;
        width: 100%;
        text-align: left !important;
    }

    .navbar-brand {
        margin-bottom: 1rem;
    }

    .navbar-nav {
        /* flex-direction: column; */
        margin: 0;
    }

    .nav-link {
        margin-left: 0;
        margin-bottom: 0.5rem;
    }
}

/* Responsive Styling for Screens Smaller Than 576px */
@media (max-width: 576px) {
    .logo-width {
        width: 7rem;
        height: 7rem;
    }

    .dropdown-multi-column .dropdown-menu .row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
    }

    .dropdown-multi-column .dropdown-menu .col {
        flex: 0 0 100%;
        /* Full width in very small screens */
    }


    .nav-link {
        margin-left: 0;
        margin-bottom: 0.5rem;
    }

    .search-form {
        width: 100%;
    }
}