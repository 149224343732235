 .leadgeneration-container{
    text-align: center;
 }
 .leadgeneration-title{
    font-size: 1.7rem;
    font-weight: bold;
    margin-bottom: 1rem;
 }
 .make{
    font-size: 1.2rem;
 }
 .digital-marketing-list1 {
    list-style-type: disc; /* Use disc bullets */
    padding-left: 0; /* Remove default padding */
    margin-left: 0; /* Remove default margin */
}

.digital-marketing-list1 li {
    margin-bottom: 8px; /* Space between list items */
    line-height: 1.5; /* Improved readability */
    text-align: left; /* Ensure text is left-aligned */
}

/* Optional: Add space around the Row */
.row {
    margin-bottom: 16px; /* Space below the row */
}
.digital-marketing-list1-txt{
    font-size: 1.2rem;
}



