/* Container for centering and responsiveness */
.alice-carousel__prev-btn,
.alice-carousel__next-btn {
  display: none !important; /* Forcefully hide the buttons */
}
.alice-carousel__dots {
  display: none !important; /* Forcefully hide the dots */
}





.carousel-wrapper {
  position: relative; /* Make wrapper relative for absolute buttons */
  padding: 20px; /* Add some padding to avoid arrow overlap */
}


/* Hide pagination dots */


.customerdiv {
  text-align: center;
  margin-top: 0.4rem;
  /* Adjust margin as needed */
}

/* Carousel wrapper styling */
.carousel-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
}

/* Carousel container styling */
.carousel-container1 {
  width: 90%;
  height: auto;
  /* Adjust height for responsiveness */
  padding: 2rem;
  max-width: 1000px;
  /* Increased max-width for the carousel container */
  overflow: hidden;
  position: relative;
  border-radius: 15px;
  /* Rounded corners for carousel container */
  /* Light gradient background */
}

/* Hide carousel indicators */
.carousel-indicators {
  display: none;
}

/* Ensure carousel items are displayed as block and centered */
.carousel-item-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 18rem;
  /* Adjust height as needed */
  width: 100%;
  /* Full width of container */
  padding: 1rem;
  /* Add padding to ensure spacing around the card */
}

.ant-card .ant-card-body {
  padding: 0;
}

/* Center card content */
.carousel-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  max-width: 420px;
  color: #000000;
  padding: 0; /* Remove padding */
  border: none; /* Remove border */
  background: none; /* Remove background */
  box-shadow: none; /* Remove shadow */
  margin: auto; /* Center the card horizontally */
}

/* Card avatar styling */
.avatar-review {
  width: 4rem;
  /* Adjust avatar size */
  height: 4rem;
  /* Adjust avatar size */
  margin-bottom: 1rem;
  border-radius: 50%;
  /* Make the avatar circular */
}

/* Card title styling */
.carousel-card h5 {
  font-size: 1rem;
  /* Smaller font size for title */
  color: #070707;
  margin: 0.5rem 0;
}

/* Card review styling */
.carousel-card p {
  font-size: 0.7rem;
  /* Adjust font size for review text */
  color: #070707;
  line-height: 1.4;
  margin-bottom: 1rem;
}

/* Hide carousel controls (Next and Prev icons) */
.carousel-control-prev,
.carousel-control-next {
  display: none;
}

/* Media queries for different screen sizes */
@media (max-width: 1024px) {
  .carousel-container1 {
    height: auto;
    /* Adjust height for larger screens */
  }
}

@media (max-width: 768px) {
  .carousel-card {
    max-width: 300px;
    /* Further adjust card size for smaller screens */
  }
}

@media (max-width: 480px) {
  .carousel-card {
    max-width: 250px;
    /* Even smaller card size */
  }
}

@media (max-width: 425px) {
  .carousel-card {
    max-width: 250px;
    /* Even smaller card size */
    height: 30rem;
  }
}

@media (max-width: 375px) {
  .carousel-card {
    max-width: 250px;
    /* Even smaller card size */
    height: 30rem;
  }
}