/* Container for features section */
.features-container {
    padding: 0 2rem;
    text-align: center;
    /* Center align text */
}

/* Flex container for weoffers and additional text */


/* Styles for weoffers section */
.weoffers-container {
    /* Stack elements vertically */
    /* Center items vertically */
    /* Center items horizontally */
    text-align: center;
}

/* Styles for weoffers section */
.weoffers {
    font-style: italic;
    color: #333;
    font-weight: bold;
    font-size: 3rem;
}


/* Ant Design Card Styles */
.ant-cards {
    border-radius: 2rem;
    /* Rounded corners for a modern look */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    /* Soft shadow for depth */
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
    margin-bottom: 1.5rem;
    text-align: left;
    background: white;
    color: black;
    /* Orange text color on hover */
    padding: 1.5rem;
    /* Padding for content spacing */
    border: 1px solid #ff8c00;
    /* Orange border for a touch of color */
}

/* Hover effects for the card */
.ant-cards:hover {
    transform: translateY(-10px);
    /* Slight lift effect */
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.3);
    /* Enhanced shadow */
    background: linear-gradient(45deg, rgba(255, 126, 0, 0.3), rgba(255, 180, 0, 0.3), rgba(255, 95, 0, 0.3), rgba(255, 154, 0, 0.3));
    color: black;
    /* White text color for high contrast */
}

/* Card title styling */
.ant-cards-head-title {
    font-weight: bold;
    font-size: 1.5rem;
    color: #fff;
    /* White title text */
    margin-bottom: 1rem;
}

/* Additional content inside the card */
.ant-cards-body {
    font-size: 1rem;
    line-height: 1.6;
    color: #fff;
    /* White text for the card body */
}

/* Button/link styles */
.learnmore {
    text-align: center;
    margin-top: 2rem;
}

.learnmore a {
    color: #fff;
    /* White link text */
    text-decoration: none;
    font-weight: bold;
    border: 1px solid #ff8c00;
    /* Orange border for emphasis */
    padding: 0.5rem 1rem;
    border-radius: 4px;
    background-color: #333;
    /* Black background for the button */
    transition: background-color 0.3s ease, color 0.3s ease;
}

.learnmore a:hover {
    background-color: #d57a0a;
    /* Orange background on hover */
    color: #fff;
    /* White text on hover */
}

.additional-text {
    font-size: 1rem;
    color: #666;
}

.learnmore a:hover {
    text-decoration: underline;
    /* Underline on hover */
    color: #080808;
    /* Darker color on hover for better visibility */
}


/* Different shades of gray for each card */
 

.ant-card:nth-child(2) {
    background-color: #c0c0c0;
    /* Medium light gray */
}

.ant-card:nth-child(3) {
    background-color: #a0a0a0;
    /* Medium gray */
}

.ant-card:nth-child(4) {
    background-color: #808080;
    /* Darker gray */
}

.ant-card:nth-child(5) {
    background-color: #606060;
    /* Even darker gray */
}

.how-text {
    font-size: 3rem;
    font-weight: bold;
}

.discover-button {
    background-color: rgb(253, 126, 20) ;
    color: white;
    border-radius: 1.5rem;
    font-size: 1rem;
}

.discover-button:hover {
    background-color: white;
    color: orange !important;
    border-color: orange !important;
}

.additional-text {
    font-size: 1.5rem;
}

.icon-down {
    color: orange;
    font-size: 2rem;
    margin-bottom: 2rem;

}

/* Hover effects */


/* Card title styling */
.ant-card-head-title {
    font-weight: bold;
    /* Bold title */
    font-size: 1.25rem;
    /* Adjust title size */
    color: #333;
    /* Dark text color for better readability */
}

.spann {
    color: rgb(253, 126, 20);
}


/* Responsive Styles */
@media (max-width: 768px) {
    .weoffers-container {
        flex-direction: column;
    }

    .weoffers {
        margin-bottom: 1rem;
        /* Adjust margin to give space between elements */
    }

    .weoffers,
    .additional-text {
        padding: 1rem;
        /* Adjust padding for smaller screens */
        font-size: 1.5rem;
        /* Adjust font size */
    }

    .ant-card {
        margin-bottom: 1rem;
        /* Adjust spacing between cards */
    }
}

@media (max-width: 480px) {
    .features-container {
        padding: 1rem;
        /* Further reduce padding */
    }

    .weoffers,
    .additional-text {
        padding: 0.75rem;
        /* Further reduce padding inside sections */
        font-size: 1.5rem;
        /* Adjust font size for smaller screens */
    }

    .ant-card {
        margin-bottom: 0.5rem;
        /* Adjust spacing between cards */
    }
}