/* Ensure html and body take full height */
html, body {
    height: 100%;
    margin: 0;
    padding: 0;
}

/* Background container for video */
.back {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Text Styling */
.affordable-text {
    color: white;
    font-size: 3rem;
    font-weight: bold;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 1;
    white-space: nowrap;
    overflow: hidden; /* Hides the overflowing text while typing */
    animation: typing 3.5s steps(30, end) 1s infinite, blinkCaret 0.75s step-end infinite;
}

/* Typewriter animation */
@keyframes typing {
    0% { width: 0; }
    50% { width: 100%; } /* Show full text */
    100% { width: 0; } /* Hide text after full width is shown */
}

/* Caret blinking effect */
@keyframes blinkCaret {
    50% { border-color: transparent; }
    100% { border-color: white; }
}

/* Ensure the video takes up the entire background without stretching */
.back video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    object-fit: cover;
    z-index: -1;
}

/* Media Queries for responsiveness */
@media (max-width: 1024px) {
    .affordable-text {
        font-size: 3rem;
    }
}

@media (max-width: 768px) {
    .affordable-text {
        font-size: 2rem;
    }
}

@media (max-width: 576px) {
    .affordable-text {
        font-size: 1.8rem;
    }
}
