.agilemethod-container {
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
}

.agilemethod-heading {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.agilemethod-subheading {
    font-size: 1.5rem;
    color: #666;
    margin-bottom: 2rem;
}

.agilemethod-body {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 2rem;
}

.agilemethod-image {
    max-width: 100%;
    
    height: 25rem;
    width: 30rem;
    display: block;
    margin: 0 auto;
}

/* Responsive Design */
@media (max-width: 768px) {
    .agilemethod-heading {
        font-size: 2rem;
    }

    .agilemethod-subheading {
        font-size: 1.25rem;
    }

    .agilemethod-body {
        font-size: 0.9rem;
    }
}
