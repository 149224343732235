.backhero-digital {
     background-image: url("../../../public/images/hero.jpg");
    /* Adjust path based on your setup */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 70vh;
    /* Set the height to cover the viewport */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.backhero-digital::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 70%;
    background: rgba(0, 0, 0, 0.6);
    /* Light black layer with 30% opacity */
    z-index: 1;
    /* Ensure it sits on top of the background image */
}

.main-herodigital {
    z-index: 5;
    color: white;
}

.hero-first-text {
    font-size: 2rem;
    font-weight: 700;
}

.grow-text {
    color: rgb(253, 126, 20);
    font-size: 1.3rem;
}

.form-digital {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-top: 4rem;
    background-color: rgba(255, 255, 255, 0.2);
    /* Semi-transparent white background */
    padding: 1rem 2rem;
    /* Add padding as needed */
    border-radius: 8px;
    /* Optional: Rounded corners for a softer look */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    /* Subtle shadow for depth */
}

.proposal-button {
    color: white;
    background-color: rgb(253, 126, 20);
    border: none;
    font-size: large;
    font-weight: bold;
    height: 3rem;
    width: 28rem;
}

.proposal-button:hover {
    color: rgb(253, 126, 20) !important;
    background-color: white(253, 126, 20) !important;
    border: 2px solid rgb(253, 126, 20) !important;
}

.input-digital {
    height: 3rem;
}

@media (max-width: 776px) {
    .main-herodigital {
        margin-top: 5rem;
    }

    .grow-text {
        font-size: 1rem;
    }

    .hero-first-text {
        font-size: 1rem;
    }

    .form-digital {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .proposal-button {
        width: auto;
    }

}
@media (max-width: 425px) {

    .proposal-button {

        font-size: medium;

    }
}