.heroseo {
    background-image: url("../../../public/images/seobackground.jpg");
    /* Adjust path based on your setup */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 70vh;
    /* Set the height to cover the viewport */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative; /* Ensure ::before pseudo-element is positioned correctly */
}

.heroseo::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
     
    /* Light black layer with 30% opacity */
    z-index: 1;
    /* Ensure it sits on top of the background image */
}

.main-heroseo {
    z-index: 5;
    color: white;
}

.hero-first-text-seo {
    font-size: 2rem;
    font-weight: 700;
}

.text-seo1 {
    color: rgb(253, 126, 20);
    font-size: 1.3rem;
}

.form-seo {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-top: 4rem;
    background-color: rgba(255, 255, 255, 0.2);
    /* Semi-transparent white background */
    padding: 1rem 2rem;
    /* Add padding as needed */
    border-radius: 8px;
    /* Optional: Rounded corners for a softer look */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    /* Subtle shadow for depth */
}

.proposal-button-seo {
    color: white;
    background-color: rgb(253, 126, 20);
    border: none;
    font-size: large;
    font-weight: bold;
    height: 3rem;
    width: 28rem;
}

.proposal-button-seo:hover {
    color: rgb(253, 126, 20) !important;
    background-color: white !important;
    border: 2px solid rgb(253, 126, 20) !important;
}

.input-seo {
    height: 3rem;
}

@media (max-width: 776px) {
    .main-heroseo {
        margin-top: 5rem;
    }

    .text-seo1 {
        font-size: 1rem;
    }

    .hero-first-text-seo {
        font-size: 1rem;
    }

    .form-seo {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .proposal-button-seo {
        width: auto;
    }
}

@media (max-width: 425px) {
    .proposal-button-seo {
        font-size: medium;
    }
}
