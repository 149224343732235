.aboutdigital-main2 {
    padding: 2rem;
}

.proven-text-digital2 {
    text-align: center;
    font-size: 1.5rem; /* Adjust as needed */
    margin-bottom: 1rem;
    font-weight: bold;
}

.engage-text2 {
    text-align: center;
    font-size: large;
    margin-bottom: 2rem;
}

.image-col2 {
    display: flex;
    justify-content: center;
    align-items: center;
}

.image2 {
    width: 100%;
    height: auto;
}

.all-text-digital12 {
    font-size: large;
    line-height: 1.6;
    margin-top: 3rem;
}

/* Add margin-top to all rows */
.ant-row {
    margin-top: 2rem; /* Adjust as needed */
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .proven-text-digital2 {
        font-size: 1.25rem;
    }

    .engage-text2 {
        font-size: medium;
    }

    .all-text-digital12 {
        font-size: medium;
    }
}

@media (max-width: 576px) {
    .proven-text-digital2 {
        font-size: 1rem;
    }

    .engage-text2 {
        font-size: small;
    }

    .all-text-digital12 {
        font-size: small;
    }

    .image-col2 {
        margin-bottom: 1rem;
    }
}
