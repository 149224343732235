/* Container for all content */
.wholecontent {
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 0 1rem; /* Padding for better content spacing on small screens */
    
  }
  
  /* Container for the heading */
  .header-container {
    text-align: center;
    margin-bottom: 2rem; /* Space below the heading */
  }
  
  /* Style for the heading */
  .privacy {
    font-weight: bold;
    font-size: 2rem; /* Adjust as needed */
  }
  
  /* Style for the paragraph content */
  .privacycontent {
    text-align: left;
    padding-left: 3rem;
    padding-right: 3rem;
    margin-bottom: 4rem;
     
  }
  .privacycontent h6{
    font-weight: bold;
    color: rgb(77, 76, 76);
  }
  
  /* Style for h6 elements */
  .privacycontent h6 {
    font-size: 1rem; /* Adjust font size as needed */
    margin-bottom: 1rem; /* Space below each paragraph */
    line-height: 1.5; /* Improved readability */
  }
  .span{
    color:rgb(253, 126, 20) ;
  }
  
  /* Media queries for responsiveness */
  @media (max-width: 1200px) {
    .privacy {
      font-size: 1.75rem; /* Slightly smaller font size for medium screens */
    }
  
    .privacycontent h6 {
      font-size: 0.9rem; /* Slightly smaller font size for medium screens */
    }
  }
  
  @media (max-width: 768px) {
    .privacy {
      font-size: 1.5rem; /* Smaller font size for small screens */
    }
  
    .privacycontent h6 {
      font-size: 0.85rem; /* Smaller font size for small screens */
    }
  }
  
  @media (max-width: 480px) {
    .privacy {
      font-size: 1.25rem; /* Even smaller font size for very small screens */
    }
  
    .privacycontent h6 {
      font-size: 0.8rem; /* Smaller font size for very small screens */
    }
  }
  