/* Universal box-sizing rule */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

/* Remove overflow from body */
body {
    overflow-x: hidden;
    /* Prevent horizontal scrolling */
}

/* Main container styling */
.container-digital3 {
    max-width: 100vw;
    /* Prevents container from exceeding viewport width */
    padding: 0 15px;
    /* Adds padding to avoid touching edges */
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Center align the container content */
    overflow: hidden;
    /* Ensure no horizontal overflow */
}

/* Text styling */
.text1-digital3 {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
}

.text2-digital3 {
    font-size: 1.2rem;
    text-align: center;
    margin-bottom: 1rem;
}

/* SEO container - Grid layout for the cards */
.seo-container3 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    /* Flexible columns */
    gap: 1rem;
    /* Space between cards */
    width: 100%;
    /* Take full width of container */
    margin: 0 auto;
    /* Center the grid */
    overflow: hidden;
    /* Avoid any overflow */
}

/* Card styling */
.ant-card-why {
    width: 100%;
    /* Ensure the card fills the available width */
    border: none !important;
    background: transparent !important;
    box-shadow: none !important;
}

/* Card title styling */
.ant-card-head-title {
    font-size: 1.25rem;
    font-weight: bold;
    text-decoration: none;
    /* No underline */
}

/* Button styling */
.digital-button3 {
    display: flex;
    justify-content: center;
    /* Center the button */
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.proposal-button13 {
    color: white;
    background-color: rgb(253, 126, 20);
    border: none;
    font-size: large;
    font-weight: bold;
    height: 3rem;
    width: 22rem;
    margin: 0 auto;
    /* Center the button */
}

.card-title {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
}

.card-icon-choose {
    display: flex;
    justify-content: left;
    gap: 1rem;
    align-items: center !important;
    font-size: 1.2rem !important;
    color: rgb(253, 126, 20) !important;
    /* Icon color (match to your design) */
}

/* Responsive breakpoints */
@media (max-width: 1024px) {
    .seo-container3 {
        grid-template-columns: repeat(2, 1fr);
        /* Two columns on medium screens */
    }
}

@media (max-width: 768px) {
    .ant-card-why {
        flex: 1 1 calc(50% - 1rem); /* Two cards per row on smaller screens */
    }
}

@media (max-width: 425px) {

    .text1-digital3,
    .text2-digital3 {
        font-size: 1rem;
        /* Adjust font size for smaller screens */
    }

    .proposal-button13 {
        font-size: medium;
        /* Adjust button size */
        width: 100%;
        /* Ensure button takes full width */
    }
}

@media (max-width: 375px) {

    .text1-digital3,
    .text2-digital3 {
        font-size: 1rem;
    }
}
