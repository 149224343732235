.aboutdigital-main {
    margin-top: 4rem;
    margin-bottom: 3rem;

}

.proven-text-digital {
    font-size: 1.7rem;
    font-weight: bolder;
}

.special-text {
    font-weight: bolder;
}

.all-text-digital {
    font-size: 1.1rem;
}
